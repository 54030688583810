<template>
  <div class="home" :class="[colorTheme]">
    <v-app id="inspire">
      <NavDrawer/>
      <AppBar/>
      <CartContent/>
      <Footer/>
      <AppModal/>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import CartContent from '@/components/CartContent.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'
import AppModal from '@/components/AppModal.vue'

export default {
  name: 'home',
  components: {
    CartContent,
    NavDrawer,
    AppBar,
    Footer,
    AppModal
    
  },
  computed:{
    colorTheme: function () {
      return this.$store.state.shop.color_theme;
    },
  }
}
</script>
